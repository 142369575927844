<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row class="d-flex justify-content-around">

        <!-- Search -->
        <b-col
          cols="12"
          md="10"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input

              class="d-inline-block mr-1"
              placeholder="Wyszukaj..."
            />

          </div>
        </b-col>

        <b-col
          cols="12"
          md="2"
          class="d-flex justify-content-end"
        >
          <b-button
            v-b-modal.modal-lg
            variant="primary"
            class="btn-icon"
          >
            Dodaj kontrahenta
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>

      </b-row>

    </div>

    <b-table
      :items="contractors"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <!--          <feather-icon-->
          <!--            :id="`invoice-row-${data.item.id}-send-icon`"-->
          <!--            icon="SendIcon"-->
          <!--            class="cursor-pointer"-->
          <!--            size="16"-->
          <!--          />-->
          <!--          <b-tooltip-->
          <!--            title="Send Invoice"-->
          <!--            class="cursor-pointer"-->
          <!--            :target="`invoice-row-${data.item.id}-send-icon`"-->
          <!--          />-->

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'apps-contractor-preview', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Contractor"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-contractor-preview', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteContractor(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>

    <b-modal
      id="modal-lg"
      :hide-footer="true"
      centered
      size="lg"
      title="Dodaj kontrahenta"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Nazwa firmy"
            rules="required"
          >
            <b-form-group
              label="Nazwa firmy"
              label-for="companyName"
            >
              <b-form-input
                id="companyName"
                v-model="contractorData.Name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- NIP -->
          <validation-provider
            #default="validationContext"
            name="NIP"
            rules="required"
          >
            <b-form-group
              label="NIP"
              label-for="nip"
            >
              <b-form-input
                id="nip"
                v-model="contractorData.Nip"
                :state="getValidationState(validationContext)"
              />

              <b-button
                v-show="contractorData !== undefined && contractorData.Nip !== undefined && contractorData.Nip.length === 10 && !isLoading"
                variant="primary mt-2"
                @click="fetchGusData(contractorData.Nip)"
              >Pobierz dane z GUS</b-button>

              <b-button
                v-show="contractorData !== undefined && contractorData.Nip !== undefined && contractorData.Nip.length === 10 && isLoading"
                variant="primary mt-2"
                disabled=""
              >Proszę czekać...</b-button>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- REGON -->
          <validation-provider
            #default="validationContext"
            name="REGON"
            rules="required"
          >
            <b-form-group
              label="REGON"
              label-for="regon"
            >
              <b-form-input
                id="regon"
                v-model="contractorData.Regon"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Province -->
          <validation-provider
            #default="validationContext"
            name="Województwo"
            rules="required"
          >
            <b-form-group
              label="Województwo"
              label-for="province"
            >
              <b-form-input
                id="province"
                v-model="contractorData.Province"
                type="text"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- District -->
          <validation-provider
            #default="validationContext"
            name="Powiat"
          >
            <b-form-group
              label="Powiat"
              label-for="district"
            >
              <b-form-input
                id="district"
                v-model="contractorData.District"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- District -->
          <validation-provider
            #default="validationContext"
            name="Gmina"
            rules="required"
          >
            <b-form-group
              label="Gmina"
              label-for="community"
            >
              <b-form-input
                id="community"
                v-model="contractorData.Community"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- District -->
          <validation-provider
            #default="validationContext"
            name="Miejscowość"
            rules="required"
          >
            <b-form-group
              label="Miejscowość"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="contractorData.City"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- District -->
          <validation-provider
            #default="validationContext"
            name="Kod pocztowy"
            rules="required"
          >
            <b-form-group
              label="Kod pocztowy"
              label-for="zipCode"
            >
              <b-form-input
                id="zipCode"
                v-model="contractorData.ZipCode"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- District -->
          <validation-provider
            #default="validationContext"
            name="Ulica"
            rules="required"
          >
            <b-form-group
              label="Ulica"
              label-for="street"
            >
              <b-form-input
                id="street"
                v-model="contractorData.Street"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon
                icon="PlusSquareIcon"
                size="18"
                class="mr-50"
              />
              Dodaj
            </b-button>
          </div>

        </b-form>
      </validation-observer>

    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BForm, BFormGroup, BFormInvalidFeedback,
  BTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import axios from 'axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import Swal from 'sweetalert2'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BTooltip,
    BDropdown,
    BDropdownItem,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          sortable: true,
        },
        {
          key: 'Name',
          label: 'Nazwa firmy',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      contractors: [],
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const blankContractorData = {
      Name: '',
      Nip: '',
      Regon: '',
      Province: '',
      District: '',
      City: '',
      Community: '',
      ZipCode: '',
      Street: '',
    }

    const contractorData = ref(JSON.parse(JSON.stringify(blankContractorData)))
    const resetcontractorData = () => {
      contractorData.value = JSON.parse(JSON.stringify(blankContractorData))
    }
    const onSubmit = () => {
      axios.post(`${process.env.VUE_APP_API_URL}Contractor`, contractorData.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        emit('refetch-data')
        Swal.fire(
          'Pomyślnie dodano kontrahenta',
          '',
          'success',
        )
      }).catch(error => {
        console.log(error)
        if (error.response.data.data.Name !== undefined && error.response.data.data.Name[0] === 'The name has already been taken.') {
          Swal.fire('Kontrahent o podanej nazwie już istnieje w systemie', '', 'error')
        }
        if (error.response.data.data.Nip !== undefined && error.response.data.data.Nip[0] === 'The nip has already been taken.') {
          Swal.fire('Kontrahent o podanym numerze NIP już istnieje w systemie', '', 'error')
        }
      })
    }
    const fetchGusData = nip => {
      isLoading.value = true
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Nip/${nip}`, config).then(response => {
        console.log(response.data[0][0])
        contractorData.value = {
          Name: response.data[0][0].name,
          Nip: nip,
          Regon: response.data[0][0].regon,
          Province: response.data[0][0].province,
          District: response.data[0][0].district,
          City: response.data[0][0].city,
          Community: response.data[0][0].community,
          ZipCode: response.data[0][0].zipCode,
          Street: response.data[0][0].street,
        }
      }).catch(e => {
        console.log(e)
      }).finally(() => {
        isLoading.value = false
      })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontractorData)

    return {
      isLoading,
      contractorData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      fetchGusData,
    }
  },
  mounted() {
    this.fetchContractors()
  },
  methods: {
    async fetchContractors() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      try {
        this.contractors = (await axios.get(`${process.env.VUE_APP_API_URL}Contractor`, config)).data
      } catch (e) {
        console.log(e)
      }
    },
    async deleteContractor(contractorId) {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      try {
        await axios.delete(`${process.env.VUE_APP_API_URL}Contractor/${contractorId}`, config)
        await Swal.fire('Usunięto kontrahenta', '', 'success')
        await this.fetchContractors()
      } catch (e) {
        console.log(e)
        await Swal.fire('Nie udało się usunąć kontrahenta', '', 'error')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
